.section-footer {
  /* background-color: lightgray; */
  /* position: absolute; */
  /* left: 0; */
  /* bottom: auto; */
  /* width: 100%; */
  text-align: center;
  padding-top: 1rem;
}

.btn-link {
  color: black;
}

/* Headline text of each page */
.main-heading {
  margin-bottom: 2rem;
  text-align: center;
}

.body-text {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.2rem;
  text-align: left;
}


/* sections are the main layouts for each page*/
.section {
  padding: 5rem 0 6rem;
  text-align: center;
  margin-top: auto;
}

#home-section {
  /* background-image: url('../images/nasaPhotoCropColor.jpg'); */
  /* background-image: url('../images/homeBackground.jpg'); */
  /* background-image: url('../images/homeBackground2.jpg'); */
  /* background-image: url('../images/homeBackground2cropped.jpg'); */
  background-size: cover;
  color: black;
  padding-bottom: 5rem;
}

#about-section {
  /* background-image: url('../images/backgroundLight.png'); */
  /* background-image: url('../images/aboutBackground.jpg'); */
  background-size: cover;
  color: black;
  padding-bottom: 5rem;
}

#portfolio-section {
  background-image: url('../images/portfolioBackground.jpg');
  background-size: cover;
  color: black;
  padding-bottom: 5rem;
}

#music-section {
  /* background-image: url('../images/nasaPhoto.jpg'); */
  background-image: url('../images/nasaPhotoSeamless.jpg');
}

#mtg-section {
  background-image: url('../images/mtgbackground.jpg');
}

#image-manipulation-section {
  background-image: url('../images/imageManipulationBackground.jpg');
}

#error-section {
  /* background-image: url('../images/errorBackground.jpg'); */
  background-image: url('../images/errorBackground2.jpg');
}

/* end sections */



/* Homepage stylings */
#homepage-img {
  position: relative;
  max-width: 80%;
  /* animation-name: banner; */
  /* animation-duration: 0.5s; */
  /* animation-delay: 0.5s; */
}

/* End Homepage styles */


/* About page styles */
#about-sub-header {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.5rem;
  text-align: center;
}

#about-img {
  position: relative;
  max-width: 100%;
  margin: 3rem auto;
}

/* End About page styles */

/* Portfolio page styling */
.portfolio-table-data {
  width: 70%;
}

.custom-portfolio-table-header-bg-color {
  background-color: rgb(101, 168, 170);
}


/* End Portfolio page styling */

/* Music page styles */
#video-container {
  margin-left: 0;
}

.videos {
  margin: 1rem 1rem 1rem 1rem;
}

.video-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.music-list-buttons {
  margin-right: 0.5rem; 
  background-color: lightgrey;
}

/* End Music page styles */


/* MTG Page styling */
.card-container {
  padding-bottom: 7rem;
}

.mtg-card-image {
  padding-bottom: 1rem;
}

.custom-mtg-table-header-bg-color {
  background-color: rgb(213, 219, 219);
}

.mtg-page-buttons {
  margin-right: 0.5rem; 
  background-color: lightgrey;
}

#mtg-mobile-button {
  visibility: hidden;
  display: none;
}

#mtg-search-button-div {
  visibility: visible;
}

/* End MTG page styling */

/* Image Manipulation page styling */
.im-page-buttons {
  margin-right: 0.5rem; 
  background-color: lightgrey;
}

#the-image-to-manipulate {
  height: 50vw;
}

/* End Image manipulation page styling */


/* Error Page styling */
#error-heading {
  color: lightgray;
  text-align: center;
  font-size: 6rem;
  font-weight: bold;
}

#error-button {
  padding: 10rem;
  padding-bottom: 13rem;
  font-size: 3rem;
  width: 90%;
  border: solid black;
}

/* End Error page styling */

#copyright {
  text-align: center;
  margin-bottom: auto;
}


/* link component styling */
.standard-link {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1rem;
  color: black;
}

#removeLinkUnderline {
  text-decoration: none;
}

#linkComponentStyle:hover {
  background: rgb(95, 158, 160, 0.5);
  /* font-size: 1.01rem; */
}

@media screen and (max-width: 770px) {
  #linkComponentStyle {
    border: 1px solid black;
    padding: 1rem;
    /* background: rgb(95, 158, 160, 0.5);  changes the background of the text in mobile mode */
    border: none;
  }
  #linkComponentStyle:hover {
    /* background: rgb(101, 168, 170); */
    background: rgb(95, 158, 160, 0.65);
    font-size: 1rem;
  }
  .videos {
    width: 80%;
    height: 50vw;
  }
  #mtg-search-button-div {
    visibility: hidden;
    display: none;
  }
  #mtg-search-button {
    visibility: hidden;
  }
  #mtg-mobile-button {
    visibility: visible;
    display: block;
  }
  #error-heading {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
  }
  
  #error-button {
    padding: 5rem;
    padding-bottom: 7rem;
    font-size: 1.5rem;
    width: 90%;
    border: solid black;
  }
}